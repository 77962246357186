@import '../../assets/styles/00-abstracts/abstracts';@import '../../assets/styles/01-mixins/mixins';.arjo-o-b2c-footer {
  margin: 30px auto 0;
  width: min(500px, 80vw);
  font-family: $font-santral;
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: 1024px) {
    margin: max(0px, calc(30vh - 500px)) min(6vw, 110px) 0 min(12vw, 216px);
    width: auto;
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 0 12px;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .link {
    font-family: $font-opensans;
    font-weight: normal;
    font-size: 13px;
    line-height: 2.31;
    color: $lava;
    text-decoration: none;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .copyright {
    font-family: $font-opensans;
    font-weight: normal;
    font-size: 13px;
    line-height: 2.31;
    color: $lava;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
